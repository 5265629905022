const state = {
  form: {},
  reward_id: "",
  type: "",
  employee_reward_id: "",
};

const mutations = {
  SET_FORM(state, payload) {
    state.form = payload;
  },
  SET_REWARD_ID(state, payload) {
    state.reward_id = payload;
  },
  SET_TYPE(state, payload) {
    state.type = payload;
  },
  SET_EMPLOYEE_REWARD_ID(state, payload) {
    state.employee_reward_id = payload;
  },
};

const getters = {
  getForm(state) {
    if (state.form) {
      return ruleRequire(state.form.mySchema);
    }
  },
  getModel(state) {
    if (state.form) {
      return getModel(state.form.mySchema);
    }
  },
};
const required = (msg) => (v) => !!v || msg;
const requiredNormal = required("*จำเป็น");

function ruleRequire(schema) {
  let item = Object.keys(schema);
  for (let index = 0; index < item.length; index++) {
    let ref_id = schema[item[index]];
    if (schema[item[index]].schema[item[index]] == undefined) {
      continue;
    }
    if (
      schema[item[index]].isRequire == true ||
      schema[item[index]].isRequire == undefined
    ) {
      ref_id.schema[item[index]].rules = [requiredNormal];
    }
  }
  return schema;
}
function getModel(schema) {
  let model = Object.keys(schema);
  let obj = model.reduce(function (acc, curr) {
    var object = {};
    object[curr] = "";
    acc[curr] = object;
    return acc;
  }, {});
  return obj;
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
};
