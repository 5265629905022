const state = {
    reward_redeemp: {},
    reward_model: {}
}

const mutations = {
    SET_EMPLOYEEREWARD(state, payload) {
        state.reward_redeemp = payload.reward_redeemp
        state.reward_model = payload.reward_model
    },
}

const getters = {}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
};