import Vue from "vue";
import VueI18n from "vue-i18n";
import { messages } from "../lang";

Vue.use(VueI18n);

// let locale = navigator.language.split("-")[0];
let locale = JSON.parse(localStorage.getItem("vuex"))
  ? JSON.parse(localStorage.getItem("vuex")).defaultSystem.language
  : "en";


export default new VueI18n({
  fallbackLocale: "en",
  locale,
  messages,
  silentFallbackWarn: true,
});
