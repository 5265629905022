import axios from "axios";
import router from '@/router'
import store from '@/store'
import Swal from 'sweetalert2/dist/sweetalert2.js'


const BackendService = axios.create({
    baseURL: process.env.VUE_APP_API_SERVICE,
    withCredentials: true,
    headers: {
        "Content-type": 'application/json',
        "Accept": 'application/json',
    }
})

BackendService.interceptors.request.use(config => {
    let token = ''

    try {
        let userStorage = localStorage.getItem('token')
        token = userStorage
    } catch (error) {
        console.log(error);
    }

    if (token) {
        config.headers.Authorization = `Bearer ${token}`
    }

    return config
})

BackendService.interceptors.response.use(undefined, function (error) {
    if (error) {
        if (error.response.status === 401) {
            store.replaceState({ defaultSystem: {} });
            localStorage.clear();
            // ========== ต้องเคลียร์ store ด้วย เนื่องจากมันจำค่า ต่อให้ localStorage.clear() แล้ว มันจะยัดค่าเดิมคืน ==========
            store.commit("login/SET_MOBILE", { mobile: "" });
            store.commit("defaultSystem/SET_USER", { role: "" }, { root: true });
            store.commit("defaultSystem/SET_TOKEN", null, { root: true });
            store.commit("defaultSystem/SET_CUSTOMER", {}, { root: true });
            setTimeout(() => {
                return router.push({
                    name: "check-login",
                });
            }, 3000);
        }
        if (error.response.status === 400) {
            Swal.fire({
                icon: "error",
                // title: "Oops! error",
                text: error.response.data.message,
                showConfirmButton: true,
                confirmButtonColor: 'red',
                confirmButtonText: 'ตกลง'
            });
        }
        if (error.response.status === 404) {
            return router.push({ name: "page-not-found" });
        }
    }
})


export default BackendService