import Axios from "@/config/customer-service";
import Swal from "sweetalert2/dist/sweetalert2.js";

const state = {
  Sidebar_drawer: null,
  SidebarColor: "success", //Change Sidebar Color || 'white', | "#2b2b2b" | "rgb(44, 59, 164)" | "rgb(96, 44, 164)" | "rgb(151, 210, 219)" | "rgb(77, 86, 100)"
  SidebarBg: "",
  navbarColor: "success",
  loading: false,
  user: {},
  customer: {},
  site: {},
  festival: {},
  language: "th",
  search: [],
  userLine: {},

};

const mutations = {
  SET_SIDEBAR_DRAWER(state, payload) {
    state.Sidebar_drawer = payload;
  },
  SET_SIDEBAR_COLOR(state, payload) {
    state.SidebarColor = payload;
  },
  SET_NAVBAR_COLOR(state, payload) {
    state.navbarColor = payload;
  },
  SET_USER(state, payload) {
    state.user = payload;
  },
  SET_TOKEN(state, payload) {
    state.token = payload;
  },
  SET_LOADING(state, payload) {
    state.loading = payload;
  },
  SET_CUSTOMER(state, payload) {
    state.customer = payload;
  },
  SET_SITE(state, payload) {
    state.site = payload;
  },
  SET_FESTIVAL(state, payload) {
    state.festival = payload;
  },
  SET_CONDITION(state, payload) {
    state.condition = payload;
  },
  SET_LANGUAGE(state, payload) {
    state.language = payload;
  },
  SET_SEARCH(state, payload) {
    state.search = payload;
  },
  SET_LINE_USER(state, payload) {
    state.userLine = payload;
  },
};


const actions = {
  checkDuplicateSearch({ commit, state }, payload) {
    const search = state.search;
    if (search.length > 0) {
      let index = search.findIndex((item) => item == payload);
      if (index == -1) {
        search.push(payload);
      }
    } else {
      search.push(payload);
    }
    return commit("SET_SEARCH", search);
  },
  updateCustomer({ commit }) {
    Axios.get("getProfile").then(({ data }) => {
      if (data.status == "success") {
        let result = data.data;
        let domain = window.location.origin;
        if (result.site != domain) {
          Swal.fire({
            icon: "error",
            html:
              "<p>ขออภัยค่ะ </br>คุณไม่มีสิทธิ์เข้าถึง website นี้ </br> กำลังไปยัง website ที่ถูกต้อง</p>",
            showConfirmButton: false,
            timer: 3000,
          }).then(function (val) {
            if (val.dismiss === Swal.DismissReason.timer) {
              return window.location.replace(result.site);
            }
          });
        } else {
          commit('SET_CUSTOMER', result)
        }
      }
    });
  }
};

const getters = {
  getLoading(state) {
    if (state.loading) {
      return state.loading;
    } else {
      return false;
    }
  },

  getCustomer(state) {
    if (JSON.stringify(state.customer) === "{}" || JSON.stringify(state.customer) === "") {
      Axios.get("getProfile").then(({ data }) => {
        if (data.status == "success") {
          let result = data.data;
          let domain = window.location.origin;
          if (result.site != domain) {
            Swal.fire({
              icon: "error",
              html:
                "<p>ขออภัยค่ะ </br>คุณไม่มีสิทธิ์เข้าถึง website นี้ </br> กำลังไปยัง website ที่ถูกต้อง</p>",
              showConfirmButton: false,
              timer: 3000,
            }).then(function (val) {
              if (val.dismiss === Swal.DismissReason.timer) {
                return window.location.replace(result.site);
              }
            });
          } else {
            state.customer = result;
            return result;
          }
        }
      });
    } else {
      return state.customer;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
