import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";
import VueSnip from "vue-snip";
import VuePlyr from "vue-plyr";
import "vue-plyr/dist/vue-plyr.css";
import "./plugins/base";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import VueThailandAddress from "vue-thailand-address";
import "vue-thailand-address/dist/vue-thailand-address.css";
import VueAwesomeSwiper from 'vue-awesome-swiper';
import VueQrcodeReader from "vue-qrcode-reader";
import i18n from './plugins/i18n.js'
import VueSocialSharing from 'vue-social-sharing'
import AOS from 'aos'
import 'aos/dist/aos.css'

Vue.use(VueSocialSharing);
Vue.use(VueSnip);
Vue.use(VueAwesomeSwiper);
Vue.use(VuePlyr);
Vue.use(VueQrcodeReader);
Vue.use(VueThailandAddress);
Vue.use(VueSweetalert2);
Vue.config.productionTip = false;

new Vue({
  vuetify,
  store,
  router,
  i18n,
  mounted() {
    AOS.init()
  },
  render: (h) => h(App),
}).$mount("#app");
