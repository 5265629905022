<template>
  <div>
    <v-dialog v-model="dialog" width="600px" persistent>
      <v-card>
        <v-card-title>
          <span class="text-h5"> {{ $t("termAndCondition") }}</span>
        </v-card-title>
        <v-card-text v-if="dialog" v-html="formatCondition(condition)">
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :color="site.color" outlined @click="handleCancel()">
            {{ $t("disagree") }}
          </v-btn>
          <v-btn
            :color="site.color"
            class="white--text"
            depressed
            @click="handleConfirm()"
          >
            {{ $t("agree") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Axios from "@/config/customer-service";
import { mapState } from "vuex";

export default {
  inject: ["isAppLoading"],
  data() {
    return {
      dialog: false,
      condition: "",
    };
  },
  methods: {
    handleCancel() {
      this.logout();
    },
    handleConfirm() {
      this.onConfirmConsent();
    },
    async logout() {
      this.dialog = false;
      this.logoutAndClear();
      this.$router.push({ name: "check-login" });
    },
    logoutAndClear() {
      this.isAppLoading(true);
      this.dialog = false;
      localStorage.clear();
      // ========== ต้องเคลียร์ store ด้วย เนื่องจากมันจำค่า ต่อให้ localStorage.clear() แล้ว มันจะยัดค่าเดิมคืน ==========
      this.$store.commit("login/SET_MOBILE", { mobile: "" });
      this.$store.commit(
        "defaultSystem/SET_USER",
        { role: "" },
        { root: true }
      );
      this.$store.commit("defaultSystem/SET_TOKEN", null, { root: true });
      this.$store.commit("defaultSystem/SET_CUSTOMER", {}, { root: true });
      setTimeout(() => {
        this.isAppLoading(false);
        this.$router.push({ name: "check-login" }).then(() => {
          location.reload();
        });
      }, 500);
    },
    getProfile() {
      return new Promise((resolve) => {
        Axios.get("getProfile")
          .then(({ data }) => {
            let dataResponse = null;
            if (data.status == "success") {
              dataResponse = { ...data.data };
            }
            resolve(dataResponse);
          })
          .catch((err) => {
            console.log("ModalConsent:getCondition", err);
            resolve(null);
          });
      });
    },
    onConfirmConsent() {
      this.isAppLoading(true);
      Axios.post("comfirmConsent")
        .then(({ data }) => {
          if (data.status == "success") {
            this.dialog = false;
          } else {
            this.logout();
          }
        })
        .catch((err) => {
          console.log("onConfirmConsent:comfirmConsent", err);
          this.logout();
        })
        .finally(() => {
          this.isAppLoading(false);
        });
    },
    getCondition() {
      Axios.get(`getCondition?site_id=${this.site?.id}`)
        .then(async (res) => {
          if (res.data.status === "success") {
            this.condition = res.data.condition;
            if (!this.condition) {
              this.condition =
                '<div style="width: 100%;height: 100%;display: flex;justify-content: center;align-items: center;">ไม่มีข้อมูล</div>';
            } else {
              const _customer = await this.getProfile();
              if (_customer.code && !_customer.status_consent) {
                this.dialog = true;
              }
            }
          }
        })
        .catch((err) => {
          console.log("ModalConsent:getCondition", err);
          this.condition =
            '<div style="width: 100%;height: 100%;display: flex;justify-content: center;align-items: center;">ไม่มีข้อมูล</div>';
        });
    },
    formatCondition(param) {
      let html = param || "";
      if (html.includes("<iframe")) {
        html = html.replaceAll("<iframe", "<iframe style='max-width: 100%;'");
      }
      if (html.includes("<img")) {
        html = html.replaceAll(
          "<img",
          "<img style='max-width: 100%;width: auto !important;height: auto !important;'"
        );
      }
      return html;
    },
  },
  created() {},
  computed: {
    ...mapState("defaultSystem", ["site", "customer"]),
  },
  watch: {
    customer: {
      handler() {
        if (this.customer) {
          if (!this.customer.status_consent) {
            this.getCondition();
          }
        }
      },
      deep: true,
    },
  },
};
</script>
