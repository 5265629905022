export default {
  register: "登録する",
  phone: "電話番号",
  welcome: "システムへようこそ",
  agreeTermAndCondition: "利用規約に同意する",
  back: "戻る",
  termAndCondition: "利用規約",
  cancel: "キャンセル",
  confirm: "確認",
  required: "この項目は必須です",
  zipcode: "郵便番号",
  amphur: "市区町村",
  district: "区",
  province: "都道府県",
  save: "保存",
  close: "閉じる",
  name: "名前",
  surname: "苗字",
  birthDay: "生年月日",
  gender: "性別",
  educationLevel: "最終学歴",
  jobLevel: "職種レベル",
  jobPosition: "職種名",
  department: "部署",
  company: "会社名",
  address: "住所",
  enterRegister: "登録する",
  management: "管理職",
  manager: "マネージャー",
  supervisor: "スーパーバイザー",
  officer: "オフィサー/スタッフ",
  worker: "ワーカー/オペレーター",
  doctorate: "博士号",
  masterDegree: "修士号",
  bachelorDegree: "学士号",
  highVocational: "高度専門士号",
  technicalVocational: "専門士号",
  vocational: "専門学校卒業",
  highSchool: "高校卒業",
  secondarySchool: "中学卒業",
  primarySchool: "小学校卒業",
  lowerPrimary: "未就学",
  male: "男性",
  female: "女性",
  other: "その他",
  profile: "プロフィール",
  id: "ID",
  nameSurname: "名前-苗字",
  sendRefCodeOtp: "OTPコードを送信する",
  requestOtpAgain: "再度OTPをリクエストする",
  edit: "編集",
  add: "追加",
  addressSendReward: "配送先住所",
  addressDetail: "住所詳細",
  selectDefautAddress: "デフォルト住所",
  addressEdit: "住所を編集する",
  addressAdd: "追加",
  defaultValue: "デフォルト値",
  hello: "こんにちは",
  coinTotal: "トータルコイン",
  homePage: "ホーム",
  activityPage: "アクティビティ",
  redemptPage: "報酬を引き換える",
  dealPage: "特別なお得情報",
  invitePage: "友達を招待する",
  addressPage: "配送先住所",
  favoritePage: "お気に入り",
  cancelShipment: "สถานะยกเลิกการจัดส่ง",
  couponPage: "私のクーポン",
  statusDeliveryPage: "報酬のステータスを確認",
  logout: "ログアウト",

  categoryAll: "全て",
  categoryRecommend: "おすすめ",
  categoryFood: "食品",
  categoryElectrical: "電子製品",
  categoryAppliance: "家電",
  categoryOther: "その他",

  dealBrand: "人気ブランドのお得な情報！！",
  deal: "今すぐチェック！",
  totalValue: "残りの量",
  detail: "詳細",
  youWant: "あなたは欲しいですか",
  delete: "削除",
  favoriteText: "お気に入りに追加",
  waitingLoad: "しばらくお待ちください",
  expireTime: "有効期限",
  coin: "コイン",
  surveyRegister: "登録フォーム",
  later: "後で",
  redeem: "交換",
  confirmRedeem: "コインを使用して交換しますか？",
  confirmAdd: "追加しますか？",
  inYourFav: "お気に入りに追加しますか？",
  confirmDelete: "削除しますか？",
  fromYourFav: "お気に入りから削除しますか？",
  balance: "残高",
  inviteFrindDetail:
    "友達を招待してアプリを使用するように促してください。友達が成功して登録すると、",
  received: "受け取りました",
  inviteFriend: "友達を招待する",
  inviteFriendPhoneText: "招待したい電話番号を入力してください",
  fillPhone: "友達の電話番号を入力してください",
  historyRedeem: "交換履歴",
  transferCoin: "Transfer Coin",
  coinReceived: "受け取ったコイン",
  coinUsed: "使用したコイン",
  activityAll: "全てのアクティビティ",
  expireDate: "有効期限",
  note: "備考",
  accessCamera: "カメラにアクセスする許可を付与してください",
  checkIn: "チェックイン",
  activityExpireDate: "アクティビティの有効期限",
  collectCoin: "コインを収集",

  activityJoin: "アクティビティに参加",
  activityQr: "QRコードをスキャン",
  activitySurvey: "アンケートに回答",
  activityUpload: "アクティビティの結果をアップロード",
  activityHeartToHeart: "ส่งข้อความ",
  activityScanQr: "QRコードをスキャン",

  sendSurvey: "アンケートを送信",
  uploadFile: "ファイルをアップロード",
  sizeFile: "ファイルサイズは10MBを超えることはできません",
  activityStatusJoin: "参加したアクティビティ",
  activityStatusWait: "保留",
  activityStatusSuccess: "กิจกรรมที่สำเร็จ",
  activityStatusReject: "กิจกรรมที่ไม่สำเร็จ",
  registerToReceive: "ลงทะเบียนเพื่อรับสิทธิ์",
  confirmUseCoupon: "ยืนยันการใช้คูปอง",
  noteUseCoupon:
    "แนะนำให้กดสิทธิ์เมื่ออยู่ที่ร้านค้าแล้ว เพื่อป้องกันไม่ให้เสียสิทธิ์",
  noteAlertUseCoupon: "รหัสจะถือว่าใช้แล้วทันที หลังกดรับสิทธิ์",
  codeDiscount: "โค้ดส่วนลด",
  errorQr: "กรุณาติดต่อเจ้าหน้าที่ผู้ดูแลระบบ",
  cancelRedemption: "คุณต้องการยกเลิก การทำรายการ? ",
  cancelRedemptionNote: "โดยจะไม่ได้รับเหรียญคืนในการยกเลิก",
  statusDelivery: "報酬の配送状況",
  statusSendIn7Day: "7日以内にお届け",
  statusDeliverySuccess: "正常に配信されました",
  statusDeliveryCancel: "配信をキャンセル",
  trackingNumber: "追跡番号",
  receiveReward: "รับของรางวัล",
  copyText: "คัดลอกแล้ว",
  confirmReceiveReward: "ยืนยันการรับของรางวัล",
  activitySuggest: "おすすめのアクティビティ",
  pageNotFound: "Not Found Page",
  goBackPage: "Go to Back Page",
  goToLogin: "Go To Login Page",
  changeLanguage: "言語を変えてください",
  all: "すべて",
  join: "参加",
  success: "成功",
  failed: "失敗しました",
  waiting: "承認待ち",
  pending: "未配信",
  sent: "配達済み",
  delivered: "配達済み",
  textRequireAgain: "以内に新しい OTP コードを再度リクエストしてください : ",
  welcomeActivity: "活動への参加を歓迎します",
  errQr: "無効な Qr コードです。もう一度お試しください。",
  requireExam: "アンケートにご記入ください。",
  uploadSuccess: "結果が送信されました。",
  uploadWaiting: "7日以内に審査が行われます。",
  errDupActivity:
    "このタスクを完了しました。 別のミッションを選択してください。",
  errExpireActivity:
    "申し訳ありませんが、アクティビティの有効期限が切れています。",
  errFileUpload: "ファイル サイズは 10 MB を超えてはなりません。",
  expireDateUse: "有効期限",
  expires: "Expires",
  registerPage: "登録ページ",
  shareActivity: "Share Activity",
  goToDeal: "Go to Deal",
  goToMission: "Go to Activity",
  menuActivity: "ミッション",
  menuDeal: "特別セール",
  menuRedeem: "報酬を引き換える",
  menuCoupon: "私のクーポン",
  redeemPoint: "แลก",
  home: "หน้าหลัก",
  activity: "กิจกรรม",
  tracking: "ติดตาม",
  account: "บัญชี",
  reward: "รางวัล",
  rewardSoHot: "รางวัลสุดฮอต",
  hotMission: "กิจกรรม กำลังมาแรง",
  recommendMission: "กิจกรรม แนะนำ",
  doneDetailMission: "รายละเอียดการเข้าร่วมกิจกรรม",
  detailMission: "รายละเอียดกิจกรรม",
  newActivityUpload: "ส่งข้อมูลอีกครั้ง",
  errNotTimeForActivity: "ยังไม่ถึงเวลาเล่นกิจกรรม",
  news: "ข่าวสาร",

  favoriteCategory: "รายการโปรด",
  deviceCategory: "เครื่องใช้ไฟฟ้า",
  accessoryCategory: "อุปกรณ์อิเล็กทรอนิกส์",
  healthCategory: "สุขภาพและความงาม",
  foodCategory: "อาหารและเครื่องดื่ม",
  babyCategory: "เด็กและของเล่น",
  petCategory: "สัตว์เลี้ยง",
  homeCategory: "บ้านและไลฟ์สไตล์",
  menCategory: "แฟชั่นผู้ชาย",
  womenCategory: "แฟชั่นผู้หญิง",
  sportCategory: "กีฬา",
  travelCategory: "ท่องเที่ยว",

  historyCoupon: "ประวัติการใช้คูปอง",
  missionLimitReached: "สิทธิเต็มแล้ว",
  rewardLimitReached: "สิทธิเต็มแล้ว",
  missionComingSoon: "เร็วๆนี้",
  missionTimeCannotJoin: "อยู่นอกช่วงเวลากิจกรรม",
  couponExpired: "คูปองหมดอายุ",
  conditionCoin: "ตามเงื่อนไข",
  agree: "ยินยอม",
  disagree: "ไม่ยินยอม",
};
