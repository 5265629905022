const state = {
    reward: ""
}

const mutations = {
    SET_PARTNERREWARD(state, payload) {
        state.reward = payload.reward
    },
}

const getters = {}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
};