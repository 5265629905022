const state = {
    mobile: "",
}

const mutations = {
    SET_MOBILE(state, payload) {
        state.mobile = payload.mobile
    },
}

const getters = {
    getMobile: (state) => state.mobile,
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
};