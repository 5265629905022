import AxiosBackend from "@/config/backend-service";
import Axios from "@/config/customer-service";
import store from "@/store";

function detectBrowser() {
    const userAgent = navigator.userAgent;

    if (userAgent.includes("Line")) {
        return "LINE In-App Browser";
    } else if (userAgent.includes("Chrome")) {
        return "Google Chrome";
    } else if (userAgent.includes("Safari") && !userAgent.includes("Chrome")) {
        return "Safari";
    } else if (userAgent.includes("Firefox")) {
        return "Firefox";
    } else if (userAgent.includes("MSIE") || userAgent.includes("Trident")) {
        return "Internet Explorer";
    } else if (userAgent.includes("Edg")) {
        return "Microsoft Edge";
    } else {
        return "Unknown Browser";
    }
}

function addParamAndReload(to) {
    let check = 'none';
    const useLineBrowser = false;
    if (useLineBrowser) return check;
    const url = new URL(window.location.href);
    const browser = detectBrowser();
    const list = allStorage();
    const token = list.find(x => x.key == "token");
    const defaultSystem = store?.state?.defaultSystem;
    const customer = defaultSystem.customer;
    const toPath = to.path;
    const watchListPage = ["/", "/checknumberLine", "/detail-user-otp"]
    // ========== ========== ========== ==========
    if (token && customer?.code &&
        !watchListPage.includes(toPath)) {
        if (!url.searchParams.has("openExternalBrowser")) {
            if (browser == "LINE In-App Browser") {
                Axios.get("getProfile").then(({ data }) => {
                    if (data.status == "success") {
                        check = 'active';
                        url.searchParams.set("openExternalBrowser", "1");
                        url.searchParams.set("token", token.value);
                        url.pathname = toPath;
                        window.location.href = url.toString();
                    }
                });
            }
        } else {
            if (url.searchParams.has("openExternalBrowser") && url.searchParams.has("token")) {
                if (browser != "LINE In-App Browser") {
                    check = 'active';
                    url.searchParams.delete("openExternalBrowser");
                    url.searchParams.delete("token");
                    url.pathname = toPath;
                    if (toPath == "/") {
                        url.pathname = "/main";
                    }
                    window.location.href = url.toString();
                }
            }
        }
    } else {
        if (url.searchParams.has("openExternalBrowser") && url.searchParams.has("token")) {
            check = 'active';
            const paramToken = url.searchParams.get("token")
            localStorage.setItem("token", paramToken)
            store.commit("defaultSystem/SET_USER", { role: "Customer" })
            url.searchParams.delete("openExternalBrowser");
            url.searchParams.delete("token");
            url.pathname = toPath;
            if (toPath == "/") {
                url.pathname = "/main";
            }
            window.location.href = url.toString();
        }
    }
    return check;
}

function allStorage() {
    let values = [];
    let keys = Object.keys(localStorage);

    for (let i = 0; i < keys.length; i++) {
        let detail = {
            key: keys[i],
            value: localStorage.getItem(keys[i]),
        }
        values.push(detail);
    }
    return values;
}

function generateBrowserFingerprint() {
    const browser = detectBrowser();
    const screenSize = window.screen.width + "x" + window.screen.height;

    // สร้าง fingerprint โดยการรวมข้อมูลและทำเป็น hash เบื้องต้น
    const fingerprint = `${browser}|${screenSize}`;
    return fingerprint;
}

async function logVisit(to, param) {
    try {
        let body = {
            "site_id": param?.site?.id || null,
            "page": to?.name || null,
            "employee_code": param?.customer?.code || null,
            "mission_id": null,
            "employee_mission_id": null,
            "reward_id": null,
            "blog_id": null,
            "mission_status": null,
            "mission_category": null,
            "reward_category": null,
            "delivery_status": null,
            "host": generateBrowserFingerprint(),
        }
        const visitLog = to?.meta?.visitLog;
        const params_map = visitLog?.params;
        const route_params = to?.params;
        if (params_map) {
            for (let key in params_map) {
                const value = params_map[key];
                const value_param = route_params[key];
                body[value] = value_param;
            }
        }

        // const test = true;
        // if (test) return;

        AxiosBackend.post("createEmployeeVisitLog", { ...body })
            .then((res) => {
                console.log(res)
            })
            .catch((err) => {
                console.log("createEmployeeVisitLog err :>> ", err);
            });
    } catch (err) { console.error("logVisit", err) }
}

export {
    addParamAndReload,
    detectBrowser,
    logVisit,
}